<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" key="all" name="all">
        <div style="position: relative" class="main-content">
          <el-container>
            <CommonTreeNew
              v-if="activeName == 'all'"
              ref="commonTree"
              treeTitle="组织架构"
              :defaultProps="Props"
              :showCheckbox="false"
              @getNodeClick="treeNodeClick"
              :urlParmas="{
            tenantId: '',
            deptCategory: '5,2,3',
            parentId: userInfo.dept_id,
          }"
              node-key="id"
              :isShowdig="false"
              :isZoom="true"
              :show="show"
              @showChange="showChange"
            ></CommonTreeNew>
            <el-main>
              <!--列表label-->
              <head-layout head-title="作业票清单" :head-btn-options=" headBtnOptions" @head-remove="handleDelete"
                           @head-add="headAdd"></head-layout>
              <!--列表查询条件和头部按钮-->
              <grid-head-layout ref="gridHeadLayoutAll" :search-columns="searchColumns" v-model="searchForm"
                                @grid-head-search="gridHeadSearch"
                                @grid-head-empty="gridHeadEmpty"></grid-head-layout>
              <grid-layout ref="gridLayOutAll" v-if="activeName == 'all'" :table-options="option"
                           :table-data="tableData"
                           :table-loading="loading" :data-total="page.total" :grid-row-btn="gridRowBtn" :page="page"
                           @page-current-change="onLoad" @page-size-change="onLoad" @grid-row-detail-click="rowView"
                           @gird-handle-select-click="selectionChange">
                <template #customBtn="{ row }">

                  <el-button
                    v-if="row.businessStatus == 'DISCLOSED' && row.characters == 'safeConfessor' && isUser(row)"
                    style="margin: 0 3px" type="text" size="small" @click="edit(row, 'flow')">安全交底
                  </el-button>
                  <el-button
                    v-if="row.businessStatus == 'SUPERVISE' && row.characters == 'supervise' && row.charactersStatus == '2' && isUser(row)"
                    style="margin: 0 3px" type="text" size="small" @click="edit(row, 'flow')">安全监管
                  </el-button>
                  <el-button
                    v-if="row.businessStatus == 'CHECKACCEPT' && row.characters == 'respUser' && isUser(row)"
                    style="margin: 0 3px"
                    type="text" size="small" @click="edit(row, 'flow')">完工验收
                  </el-button>
                  <el-button v-if="row.businessStatus == 'CONFIRMING' && isUser(row)"
                             style="margin: 0 3px" type="text" size="small"
                             @click="edit(row, 'sign')" :disabled="row.taskId == -1">签署
                  </el-button>

                  <el-button v-if="row.businessStatus == 'PREPARE' && isUser(row)"
                             style="margin: 0 3px" type="text" size="small"
                             @click="edit(row, 'edit')">编辑
                  </el-button>

                  <el-button
                    v-if="row.statuSort == '1' && row.businessStatus != 'PREPARE'&&row.businessStatus != 'CONFIRMING'&&row.businessStatus != 'CONFIRMED'"
                    style="margin: 0 3px" type="text" size="small"
                    @click="handleProgress(row)">流程进度
                  </el-button>
                  <el-button style="margin: 0 3px" type="text" size="small" @click="rowView(row, 'view')">查看
                  </el-button>
                  <el-button v-if="row.businessStatus == 'PREPARE' && isUser(row)" style="margin: 0 3px" type="text"
                             size="small"
                             @click="rowDel(row)">删除
                  </el-button>
                  <el-button v-if="row.businessStatus != 'CONFIRMING' && row.businessStatus != 'CONFIRMED'"
                             style="margin: 0 3px" type="text" size="small" @click="copy(row)">复制
                  </el-button>
                </template>
              </grid-layout>
            </el-main>
          </el-container>
        </div>

      </el-tab-pane>
      <el-tab-pane label="我的" key="my" name="my">
        <!--列表label-->
        <head-layout head-title="作业票清单" :head-btn-options="headBtnOptions" @head-remove="handleDelete"
                     @head-add="headAdd"></head-layout>
        <!--列表查询条件和头部按钮-->
        <grid-head-layout ref="gridHeadLayoutMy" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" v-model="searchForm"
                          @grid-head-empty="gridHeadEmpty"></grid-head-layout>
        <grid-layout ref="gridLayOutMy" :table-options="option" :table-data="myTableData" v-if="activeName == 'my'"
                     @grid-row-detail-click="rowView" :table-loading="myLoading" :data-total="myPage.total"
                     :grid-row-btn="gridRowBtn" :page="myPage" @page-current-change="onLoadMyPage"
                     @page-size-change="onLoadMyPage"
                     @gird-handle-select-click="selectionChange">
          <template #customBtn="{ row }">
            <el-button v-if="row.businessStatus == 'DISCLOSED' && row.characters == 'safeConfessor'"
                       style="margin: 0 3px" type="text" size="small" @click="edit(row, 'flow')">安全交底
            </el-button>
            <el-button
              v-if="row.businessStatus == 'SUPERVISE' && row.characters == 'supervise' && row.charactersStatus == '2'"
              style="margin: 0 3px" type="text" size="small" @click="edit(row, 'flow')">安全监管
            </el-button>
            <el-button v-if="row.businessStatus == 'CHECKACCEPT' && row.characters == 'respUser'" style="margin: 0 3px"
                       type="text" size="small" @click="edit(row, 'flow')">完工验收
            </el-button>
            <el-button v-if="row.businessStatus == 'CONFIRMING'" style="margin: 0 3px" type="text" size="small"
                       @click="edit(row, 'sign')" :disabled="row.taskId == -1">签署
            </el-button>

            <el-button v-if="row.businessStatus == 'PREPARE'" style="margin: 0 3px" type="text" size="small"
                       @click="edit(row, 'edit')">编辑
            </el-button>

            <el-button
              v-if="row.businessStatus != 'PREPARE'&&row.businessStatus != 'CONFIRMING'&&row.businessStatus != 'CONFIRMED'"
              style="margin: 0 3px" type="text" size="small"
              @click="handleProgress(row)">流程进度
            </el-button>
            <el-button style="margin: 0 3px" type="text" size="small" @click="rowView(row, 'view')">查看
            </el-button>
            <el-button v-if="row.businessStatus == 'PREPARE'" style="margin: 0 3px" type="text" size="small"
                       @click="rowDel(row)">删除
            </el-button>
            <el-button v-if="row.businessStatus != 'CONFIRMING' && row.businessStatus != 'CONFIRMED'"
                       style="margin: 0 3px" type="text" size="small" @click="copy(row)">复制
            </el-button>
          </template>
        </grid-layout>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="作业票类型选择" :visible.sync="kictectType" width="940px">
      <el-form ref="form" :model="ticketForm" label-width="185px" :rules="ticketRules">
        <el-form-item label="线上申请/线下申请:" prop="ticketRadio">
          <el-radio-group v-model="ticketForm.ticketRadio" @change="chooseTicket">
            <el-radio :label="0">线上申请</el-radio>
            <el-radio :label="1">线下申请和审批</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="作业票类型:" v-show="ticketForm.ticketRadio == 1" prop="ticketRadio">
          <el-select v-model="ticketForm.jobTicketType" placeholder="请选择作业票类型">
            <el-option v-for="item in kictectTypeList" :key="item.dictKey" :label="item.dictValue" :value="item.dictKey">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <offlineImg v-if="ticketForm.ticketRadio == 1" :disabled="true" ref="offlineImg"></offlineImg> -->

      </el-form>

      <div class="kicket-content" v-show="ticketForm.ticketRadio == 0">
        <div v-for="(item, index) in kictectTypeList" :key="index" class="kicket" @click="selectKicket(item)">
          <div :style="computedStyle">
            <img class="img-item" :src="item.kictectIcon" alt=""/>
          </div>
          <div class="text-item">{{ item.dictValue }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTicket">{{
            $t("cip.cmn.btn.defBtn")
          }}
        </el-button>
        <el-button @click="kictectType = false">{{
            $t("cip.cmn.btn.celBtn")
          }}
        </el-button>
      </div>
    </el-dialog>
    <common-dialog v-if="bpmnVisible" width="70%" customClass="wf-dialog"
                   :dialogTitle="$t(`cip.desk.workflow.title.indexHeadVisible`)" :showBtn="false"
                   @cancel="bpmnVisible = false">
      <wf-design ref="bpmn" style="height: 60vh" :options="bpmnOption"></wf-design>
    </common-dialog>

    <!-- 流程进度弹框 -->
    <common-dialog v-if="circulationVisible" width="35%"
                   :dialogTitle="$t(`cip.plat.wfOps.process.title.processProgressTitle`)" :showBtn="false"
                   @cancel="circulationVisible = false">
      <wf-flow :flow="flow"></wf-flow>
    </common-dialog>
    <!--流程用户弹框-->
    <process-user-dialog ref="processUser" @getUserSelection="handleUserSelection"></process-user-dialog>
    <CommonDialog v-if="processVisible" width="70%" dialogTitle="流程进度" @cancel="processVisible = false"
                  :showBtn="false">
      <bpmn-flow ref="bpmnFlow" :bpmnOption="bpmnOption" :flow="flow"></bpmn-flow>
    </CommonDialog>
    <fileView ref="fileView" title="附件预览"></fileView>
  </div>
</template>
<script>
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";
// import GridHeadLayout from "@/views/components/layout/grid-head-layout";
import {detail, processList as getProcessList} from "@/api/plugin/workflow/process";
import {
  ticketType,
  myWtTicketPage,
  copy,
  wtTicketRemove, myWtTicketSubmit, notSubmittedForSignature, notSubmittedForSignatureSubmit
} from "@/api/workTicket";
import {mapGetters} from "vuex";
import CommonDialog from "@/components/CommonDialog";
import TemplateType from '@/views/business/jobSlip/V1/jobSlipInventory/components/templateType';
import WfFlow from "@/views/plugin/workflow/process/components/flow.vue";
import {trainingPlanIdUpdate} from "@/api/safe";
import {removeInfo} from "@/api/knowledgeClassifcation/knowledge";
import ProcessUserDialog from "@/views/business/components/processUserDialog";
import {getToken} from "@/util/auth";
import {dictionaryBiz} from "@/api/reportTasks";
import offlineImg from "@/views/business/jobSlip/jobTicketList/offlineImg";
import {getDeptTree} from "@/api/system/dept";
import bpmnFlow from "@/views/plugin/workflow/components/process-bpmn-flow/index.vue";

export default {
  data() {
    return {
      authUser: '',
      processVisible: false,
      processId: "",
      userText: "",
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
      loading: true,
      show: true,
      Props: {
        children: "children",
        label: "title",
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      isSubmit: false,
      tableData: [],
      myLoading: true,
      myPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      ticketForm: {
        ticketRadio: 0,
        ticketFile: '',
        jobTicketType: "gkzy",
        jobTicketName: '高空作业'
      },
      ticketRules: {
        ticketRadio: [
          {required: true, message: '请选择线上/线下申请', trigger: 'change'}
        ],
        ticketFile: [
          {required: true, message: '请上传作业票扫描件', trigger: 'change'}
        ],
      },
      dataForm: {},
      myTableData: [],
      selectionList: [],
      activeName: "all",
      kictectType: false,
      kictectIcon: [
        require("@/assets/images/kicket/gkzy1.png"),
        require("@/assets/images/kicket/dtzy1.png"),
        require("@/assets/images/kicket/dhzy1.png"),
        require("@/assets/images/kicket/dzzy1.png"),
        require("@/assets/images/kicket/sxkjzy1.png"),
        require("@/assets/images/kicket/lsydzy1.png"),
        require("@/assets/images/kicket/dlzy1.png"),
        require("@/assets/images/kicket/tdzy1.png"),
        require("@/assets/images/kicket/sdzy1.png"),
        require("@/assets/images/kicket/mbcdzy1.png"),
      ],
      kictectTypeList: [],
      router_dict:{
        gkzy:{
          kictectIcon:require("@/assets/images/kicket/gkzy1.png"),
          path: `/jobSlip/jobTicketList/heightWork`,
        },
        dtzy:{
          kictectIcon:require("@/assets/images/kicket/dtzy1.png"),
          path: `/jobSlip/jobTicketList/wtBaseDirt`,
        },
        dhzy:{
          kictectIcon:require("@/assets/images/kicket/dhzy1.png"),
          path: `/jobSlip/jobTicketList/hotWork`,
        },
        dzzy:{
          kictectIcon:require("@/assets/images/kicket/dzzy1.png"),
          path: `/jobSlip/jobTicketList/hoistingWork`,
        },
        yxkj:{
          kictectIcon:require("@/assets/images/kicket/sxkjzy1.png"),
          path: `/jobSlip/jobTicketList/limitSpace`,
        },
        lsyd:{
          kictectIcon:require("@/assets/images/kicket/lsydzy1.png"),
          path: `/jobSlip/jobTicketList/electricWork`,
        },
        dlzy:{
          kictectIcon:require("@/assets/images/kicket/dlzy1.png"),
          path: `/jobSlip/jobTicketList/openCircuitWork`,
        },
        mbcd:{
          kictectIcon:require("@/assets/images/kicket/mbcdzy1.png"),
          path: `/jobSlip/jobTicketList/wtBaseBpp`,
        },
        bpzy:{
          kictectIcon:require("@/assets/images/kicket/tdzy1.png"),
          path: `/jobSlip/jobTicketList/explodeWork`,
        },
        qtzy:{
          kictectIcon:require("@/assets/images/kicket/sdzy1.png"),
          path: `/jobSlip/jobTicketList/otherWork`,
        }
      },
      searchForm:{},
      searchColumns: [
        {
          prop: "ticketCode",
          type: "input",
          span: 3,
          placeholder: "请输入作业票编号",
        },
        {
          prop: "ticketType",
          span: 3,
          placeholder: "请选择作业票类型",
          type: "select",
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
        {
          prop: "businessStatus",
          type: "select",
          span: 2,
          placeholder: "请选择状态",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: "String",
        },
        {
          prop: 'entryTime',
          type: 'datetimerange',
          span: 5,
          startPlaceholder: "进场开始时间",
          endPlaceholder: "进场结束时间",
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        },
      ],
      bpmnVisible: false,
      bpmnOption: {},
      circulationVisible: false,
      searchTitle: "title",
      defaultProps: {
        children: "children",
        label: "title",
        value: "id",
      },
      componylist: [],
      createDept: '',
      nodeData: {}
    };
  },
  components: {
    bpmnFlow,
    HeadLayout,
    GridLayout,
    CommonTree,
    CommonDialog,
    WfFlow,
    TemplateType,
    ProcessUserDialog,
    offlineImg
  },
  mixins: [exForm],

  computed: {
    ...mapGetters(["userInfo", "language", 'themeClass', 'themeColor']),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    headBtnOptions() {
      return [
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          btnOptType: "add",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-remove",
          btnOptType: "remove",
        },
      ];
    },
    gridRowBtn() {
      return [];
    },
    option() {
      return {
        linklabel: "ticketCode",
        selectable: (row, index) => {
          return row.businessStatus == "PREPARE" && this.isUser(row);
        },
        menuWidth: 210,
        column: [
          {
            label: "作业票编号",
            prop: "ticketCode",
            align: "left",
            width: "180",
            overHidden: true,
          },
          {
            label: "作业票类型",
            type: "select",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            prop: "ticketType",
            overHidden: true,
          },
          {
            label: "作业内容",
            prop: "content",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业实施时间",
            prop: "workExecutionTime",
            align: "center",
            width: "180",
            overHidden: true,
          },
          {
            label: "申请人",
            prop: "createUserName",
            align: "center",
            overHidden: true,
          },
          {
            label: "作业负责人",
            prop: "workSupervisorName",
            align: "center",
            overHidden: true,
          },
          {
            label: "申请时间",
            prop: "createTime",
            align: "center",
            width: "180",
            overHidden: true,
          },
          {
            label: "状态",
            prop: "businessStatus",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=wpt_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: "130",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
    computedStyle() {
      return [
        {
          backgroundColor: this.themeColor,
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          marginLeft: '10px',
          marginRight: '20px'
        },
      ];
    }
  },
  created() {
    this.onLoadMyPage(this.myPage);
    this.getTicketList();
  },
  mounted() {
    this.authUser = this.userInfo.user_id;
    window.addEventListener("storage", (e) => {
      if (e.key === "sinoma-refresh") {
        if (this.activeName == "my") {
          this.myPage.currentPage = 1;
          this.onLoadMyPage(this.myPage);
        } else if (this.activeName == "all") {
          this.page.currentPage = 1;
          this.onLoad(this.page);
        }
        localStorage.setItem("sinoma-refresh", JSON.stringify(false)); //变成false , 可以来回监听
      }
    });
  },

  methods: {
    isUser(row) {
      return row.createUser == this.userInfo.user_id;
    },
    showChange(v) {
      this.show = v;
    },
    copy(row) {
      let data = {}
      data.workOrg = this.userInfo.deptName;
      data.workOrgId = this.userInfo.dept_id;
      data.ticketType = row.ticketType;
      data.wtTicketId = row.id;
      this.loading = true;
      copy(data).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            this.loading = false;
            this.$message.success("复制成功！");
            if (this.activeName === "my") {
              this.myPage.currentPage = 1;
              this.onLoadMyPage(this.myPage);
            } else if (this.activeName === "all") {
              this.page.currentPage = 1;
              this.onLoad(this.page);
            }
          } else {
            this.$message.warning("复制失败！");
          }
        }
      })
    },
    treeNodeClick(node) {
      this.nodeData = node;
      this.createDept = node.id;
      this.searchForm = {};
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    // 选择线上/线下申请
    chooseTicket(e) {
      console.log(e, '593')
    },
    // 上传线下扫描图片
    handleAvatarSuccess(res) {
      if (res.code == 200) {
        this.ticketForm.ticketFile = res.data.link;
      }
    },
    // 提交线上线下作业票
    submitTicket() {
      console.log(this.ticketForm, '622')
      if (this.ticketForm.ticketRadio == 1) {
        let filter = this.kictectTypeList.filter(e => e.dictKey == this.ticketForm.jobTicketType);
        this.ticketForm.jobTicketName = filter[0].dictValue
        this.$router.push({
          path: "/jobSlip/jobTicketList/offline",
          query: {
            type: 'add',
            ticketForm: this.ticketForm
          },
        });
      }
      this.kictectType = false;
    },
    selectUser(row, flag) {
      //修改草稿状态为待审批
      let data = {
        id: row.id,
        businessStatus: "APPROVING"
      }
      if (row.processInstanceId) {
        myWtTicketSubmit(data).then(() => {
          // 刷新列表页面
          if (this.activeName === "my") {
            this.myPage.currentPage = 1;
            this.onLoadMyPage(this.myPage);
          } else if (this.activeName === "all") {
            this.page.currentPage = 1;
            this.onLoad(this.page);
          }
        })
      } else {
        this.formProcess.id = row.id
        this.formProcess.data = {...row}
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.processBusinessName = row.content
        if (!flag) {
          this.dataForm = row
        } else {
          this.dataForm = {}
        }
        //发起流程
        this.handleStartProcess6(true, flag).then(done => {
          // 更新业务数据流程实例id
          data.processInstanceId = done.data.data

          myWtTicketSubmit(data).then(() => {
            //刷新列表页面
            if (this.activeName === "my") {
              this.myPage.currentPage = 1;
              this.onLoadMyPage(this.myPage);
            } else if (this.activeName === "all") {
              this.page.currentPage = 1;
              this.onLoad(this.page);
            }
          }).then(() => {
            this.$message({
              type: "success",
              message: "操作成功",
              duration: 1000,
            });
          })
        })
      }
    },

    // 查看流程进度
    handleProgress(row) {
      const {taskId, processInstanceId} = row
      detail({taskId, processInsId: processInstanceId}).then(res => {
        const {process, flow} = res.data.data
        this.bpmnOption = {
          mode: 'view',
          xml: process.xml,
          flows: this.handleResolveFlows(flow),
          lang: this.language
        }
        this.process = process
        this.flow = flow
        this.processVisible = true
      })
    },
    // 选择流程用户
    handleUserSelection(userIds) {
      this.formProcess.assignee = userIds;
      if (JSON.stringify(this.dataForm) != '{}') {
        this.selectUser(this.dataForm, true)
      } else {
        return this.$message.warning("行数据为空，请刷新");
      }
    },
    handleClick(val) {
        this.searchForm = {};
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage);
    },
    getTicketList() {
      ticketType("work_permit_type").then((res) => {
        if (res.data.code == 200) {
        let kictectTypeList=res.data.data.map((item) => {
            return{
              ...item,
              kictectIcon:this.router_dict[item.dictKey].kictectIcon,
              routeObj:{
                path:this.router_dict[item.dictKey].path,
                query: {
                  jobTicketType:item.dictKey,
                  type: 'add'
                },
              },
            }
          });
          this.kictectTypeList = kictectTypeList;
        }
      });
    },
    headAdd() {
      this.kictectType = true;
    },
    // 新增作业票
    selectKicket(data) {
      this.kictectType = false;
      this.$router.push(data.routeObj);
    },
    edit(row, type) {
      if (row.statuSort == 2) {
        //线下
        this.$router.push({
          path: "/jobSlip/jobTicketList/offline",
          query: {
            type: type,
            jobTicketType: row.ticketType,
            ticketCode: row.ticketCode,
            id: row.id,
            businessStatus: row.businessStatus,
            characters: row.characters,
            processInstanceId: row.processInstanceId,
          }
        })
      } else {
        //线上
        let item = this.kictectTypeList.find(e => e.dictKey == row.ticketType)
        item.routeObj.query.ticketCode = row.ticketCode
        item.routeObj.query.id = row.id
        item.routeObj.query.jobTicketType = row.ticketType
        item.routeObj.query.type = type
        item.routeObj.query.businessStatus = row.businessStatus
        item.routeObj.query.characters = row.characters
        item.routeObj.query.processInstanceId = row.processInstanceId
        this.$router.push(item.routeObj)
      }
    },
    rowView(row) {
      if (row.statuSort == 2) {
        //线下
        this.$router.push({
          path: "/jobSlip/jobTicketList/offline",
          query: {
            type: 'view',
            ticketCode: row.ticketCode,
            jobTicketType: row.ticketType,
            id: row.id,
            businessStatus: row.businessStatus,
            characters: row.characters,
            processInstanceId: row.processInstanceId,
          }
        })
      } else {
        let item = this.kictectTypeList.find(e => e.dictKey == row.ticketType)
        item.routeObj.query.ticketCode = row.ticketCode
        item.routeObj.query.id = row.id
        item.routeObj.query.type = 'view'
        item.routeObj.query.jobTicketType = row.ticketType
        item.routeObj.query.businessStatus = row.businessStatus
        item.routeObj.query.characters = row.characters
        item.routeObj.query.processInstanceId = row.processInstanceId
        this.$router.push(item.routeObj)
      }
    },
    // 列表查询
    gridHeadSearch() {
      let searchForm={...this.searchForm}
      if (searchForm.entryTime) {
         searchForm.startTime = searchForm.entryTime[0]
         searchForm.endTime = searchForm.entryTime[1]
      }
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      }
    },
    // 查询重置
    gridHeadEmpty() {
      if (this.activeName == "my") {
        this.myPage.currentPage = 1;
        this.onLoadMyPage(this.myPage, this.searchForm);
      } else if (this.activeName == "all") {
        this.page.currentPage = 1;
        this.onLoad(this.page, this.searchForm);
      }
    },
    rowDel(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        return wtTicketRemove(row.id)
          .then(() => {
            if (this.activeName === "my") {
              this.myPage.currentPage = 1;
              this.onLoadMyPage(this.myPage);
            } else if (this.activeName === "all") {
              this.page.currentPage = 1;
              this.onLoad(this.page);
            }
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
          });
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      let selectionList = this.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(async () => {
        return wtTicketRemove(this.$func.join(ids))
          .then(() => {
            if (this.activeName === "my") {
              this.myPage.currentPage = 1;
              this.onLoadMyPage(this.myPage);
            } else if (this.activeName === "all") {
              this.page.currentPage = 1;
              this.onLoad(this.page);
            }
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess')
            });
          });
      });
    },
    onLoad(page, params = {}) {
      let that = this;
      that.page = page;
      that.loading = true;
      myWtTicketPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {
          type: 'all',
          ...this.searchForm,
          orgId: this.userInfo.dept_id,
          createDept: this.createDept
        })
      ).then((res) => {
        const data = res.data.data;
        that.page.total = data.total;
        that.$nextTick(() => {
          if (that.$refs.gridLayOutAll) {
            that.$refs.gridLayOutAll.page.total = data.total;
            that.$refs.gridLayOutAll.$refs.grid.$refs.table.doLayout();
          }
        });
        that.tableData = data.records;
        this.processTableData(that.tableData);
        that.loading = false;
      });
    },
    onLoadMyPage(page, params = {}) {
      let that = this;
      that.myPage = page;
      that.myLoading = true;
      myWtTicketPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, {
          type: 'my',
          ...this.searchForm,
          orgId: this.userInfo.dept_id,
          createDept: this.userInfo.dept_id
        })
      ).then(async (res) => {
        const data = res.data.data;
        that.myPage.total = data.total;
        that.$nextTick(() => {
          if (that.$refs.gridLayOutMy) {
            that.$refs.gridLayOutMy.page.total = data.total;
            that.$refs.gridLayOutMy.$refs.grid.$refs.table.doLayout();
          }
        });
        that.myTableData = data.records;
        try {
          await that.processTableData(that.myTableData);
        } catch (error) {
          // 处理 processTableData 函数可能抛出的错误
          console.error("Error in processTableData:", error);
        }
        that.myLoading = false;
      });
    },

    async processTableData(data) {
      let that = this;
      let taskId = null;
      for (const e of data) {
        if (e.businessStatus === "APPROVING") {
          // 当前流程审批选择角色时，会让审批人员与发起人同部门，如果后续变更那么该条件需变化
          if (e.createDept === this.userInfo.dept_id) {
            if (e.processInsId && e.processInsId !== "") {
              try {
                const res = await this.getTaskDetail(taskId, e.processInsId);
                getTaskUser(res.process).then(newRes => {
                  if (newRes.data.code === 200) {
                    if (newRes.data.data.includes(this.userInfo.real_name)) {
                      that.$set(e, "approve", true);
                    }
                  }
                });
              } catch (error) {
                // 处理 getTaskDetail 函数可能抛出的错误
                console.error("Error in getTaskDetail:", error);
              }
            }
          }
        }
      }
    },

  },
};
</script>

<style scoped lang='scss'>
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 262px) !important;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}

::v-deep .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}

::v-deep .el-dialog__body {
  padding: 20px;
}

.kicket-content {
  display: flex;
  //padding: 0 20px;
  flex-wrap: wrap;

  .kicket {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #EDEDED;
    width: 250px;
    height: 90px;
    border-radius: 8px;
    margin: 10px 20px;

    .img-item {
      width: 40px;
      height: 40px;
    }

    .text-item {
      color: #333333;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

}

::v-deep .bjs-powered-by {
  display: none;
}

.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
